import React from "react";
import Button from "@components/Button";
import Image from "@components/Image";
import ReactMarkdown from "react-markdown";

import "./Header.styles.scss";

const Header = ({ title, description, image, hasButton = true }) => {
  return (
    <div className="header">
      <div className="header__inner container">
        <div className="header__content">
          <h1 className="title">
            <ReactMarkdown source={title} />
          </h1>
          <p className="description">{description}</p>
          {hasButton && (
            <Button
              type="primary"
              size="big"
              className="header__content__button"
            >
              Book a demo
            </Button>
          )}
        </div>
        {image && (
          <div className="header__image">
            <Image data={image} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
